import { memoize } from '@exodus/basic-utils';

export function setDocumentDataAttribute(name: string, value?: string | null) {
  const root = document.documentElement;

  if (value === undefined || value === null) {
    delete root.dataset[name];
  } else {
    root.dataset[name] = value;
  }
}

export function setDocumentProperty(name: string, value: string = '') {
  const root = document.documentElement;
  root.style.setProperty(name, value);
}

export const canUseIntersectionObserverV2 = memoize(() => {
  try {
    // `trackVisibility` needs to be specified together with delay.
    // `IntersectionObserver` V2 will throw otherwise, which we can use to detect if it is available.
    const observer = new IntersectionObserver(() => {}, {
      trackVisibility: true,
    } as IntersectionObserverInit);
    observer.disconnect();
    return false;
  } catch {
    return true;
  }
});
